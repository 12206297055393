import model from './model';
import { RecommenderResponse } from './types';

export default model.createController(({ $w, $widget, flowAPI }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const { stores } = flowAPI.controllerConfig.wixCodeApi as any;

      const d = [
        {
          image:
            'wix:image://v1/82f27b_51dd835d7e2d4886ad50053c7b56ce73~mv2.jpg/file.jpg#originWidth=658&originHeight=360',
          title: "I'm a product",
          _id: 'df19c1f7-07d8-a265-42f8-e8dfa824cc6e',
          productId: 'df19c1f7-07d8-a265-42f8-e8dfa824cc6e',
          price: '25$',
          options: [
            {
              option: 'Color',
              selection: 'Black',
            },
          ],
        },
        {
          image:
            'wix:image://v1/82f27b_51dd835d7e2d4886ad50053c7b56ce73~mv2.jpg/file.jpg#originWidth=658&originHeight=360',
          title: "I'm a product",
          _id: 'df19c1f7-07d8-a265-42f8-e8dfa824cc6e1',
          productId: 'df19c1f7-07d8-a265-42f8-e8dfa824cc6e1',
          price: '25$',
          options: [
            {
              option: 'Color',
              selection: 'Black',
            },
          ],
        },
        {
          image:
            'wix:image://v1/82f27b_51dd835d7e2d4886ad50053c7b56ce73~mv2.jpg/file.jpg#originWidth=658&originHeight=360',
          title: "I'm a product",
          _id: 'df19c1f7-07d8-a265-42f8-e8dfa824cc6e2',
          productId: 'df19c1f7-07d8-a265-42f8-e8dfa824cc6e2',
          price: '25$',
          options: [
            {
              option: 'Color',
              selection: 'Black',
            },
          ],
        },
      ];

      getCartData()
        .then((products) => products.map(({ productId }) => productId))
        .then((ids) => {
          // callRecommenderIdsAsqueryParams(ids).then((products) => {
          //   if (products.length > 0) {
          //     setRepeaterData(products);
          //   }
          // });
          callRecommenderIdsAsBody(ids).then((products) => {
            if (products.length > 0) {
              setRepeaterData(products);
            }
          });
        })
        .catch((err) => {
          console.log('overall flow error', err);
        });

      // function callRecommenderIdsAsqueryParams(ids) {x
      //   console.log('callRecommender - ids as query params 2 - start');
      //   const params = `${ids
      //     .map((id, i) => `item_ids[${i}]=${id}`)
      //     .join('&')}`;
      //   return (
      //     flowAPI.essentials.httpClient
      //       // .get(`/_api/recommender/v1/recommendations-dev?${params}`)
      //       .get(`/_api/recommender/v1/recommendations-dev?`, {
      //         params: {
      //           item_ids: ids.join(','),
      //         },
      //       })
      //       .then((res) => {
      //         console.log(
      //           'callRecommender - ids as query params 2 - got response',
      //           res,
      //         );
      //         const { recommendations } = res.data as RecommenderResponse;
      //         const products = recommendations.items.map((item) => {
      //           return {
      //             _id: item.product_id,
      //             image: item.image,
      //             title: item.title,
      //             price: item.price,
      //             options: {
      //               choices: item.options.reduce((choices, option) => {
      //                 if (!choices[option.name]) {
      //                   const anotherChoice = {
      //                     [option.name]: option.choices[0].value,
      //                   };
      //                   return { ...choices, ...anotherChoice };
      //                 }

      //                 return choices;
      //               }, {}),
      //             },
      //           };
      //         });

      //         console.log(
      //           'callRecommender - ids as query params 2 - returned products',
      //           products,
      //         );
      //         return products;
      //       })
      //       .catch((err) => {
      //         console.log(
      //           'callRecommender - ids as query params 2 - error',
      //           err,
      //         );
      //         return [];
      //       })
      //   );
      // }
      function callRecommenderIdsAsBody(ids) {
        console.log('callRecommender - ids as body params - start');
        return flowAPI.essentials.httpClient
          .post(`/_api/recommender/v1/recommendations-dev`, {
            item_ids: ids,
            msid: 'ef935916-bab0-443a-b030-fe1f72bf79ad',
          })
          .then((res) => {
            console.log(
              'callRecommender - ids as body params - got response',
              res,
            );
            const { recommendations } = res.data as RecommenderResponse;
            const products = recommendations.items.slice(0, 3).map((item) => {
              return {
                _id: item.productId,
                productId: item.productId,
                image: item.image,
                title: item.title,
                price: item.price,
                options: {
                  choices: item.options.reduce((choices, option) => {
                    if (!choices[option.name]) {
                      const anotherChoice = {
                        [option.name]: option.choices[0].value,
                      };
                      return { ...choices, ...anotherChoice };
                    }

                    return choices;
                  }, {}),
                },
              };
            });

            console.log(
              'callRecommender - ids as body params - returned products',
              products,
            );
            return products;
          })
          .catch((err) => {
            console.log('callRecommender - ids as body params - error', err);
            return [];
          });
      }
      // setRepeaterData(d);

      // flowAPI.essentials.httpClient
      //   .post(`/api/v1/invoke/model/${modelId}`, {
      //     features: {
      //       product_guid: {
      //         str_val: productId,
      //       },
      //     },
      //     triggered_by: 'some-user',
      //   })
      //   .then((res) => {
      //     console.log('Blocks widget, viewer controller, res: ', res);
      //   })
      //   .catch((err) => {
      //     console.log('Blocks widget, viewer controller, error: ', err);
      //   });

      // fetch('/_api/recommender/recommendations?msid=ebfc6891-3313-433c-b1db-150a9af0b9a5')
      // fetch('/_api/recommender/v1/recommendations?msid=ebfc6891-3313-433c-b1db-150a9af0b9a5')
      function getCartData() {
        return stores.cart
          .getCurrentCart()
          .then((cart) => {
            console.log('getting cart data?');
            if (cart.lineItems.length > 0) {
              return cart.lineItems.map((item) => {
                return {
                  _id: item.productId,
                  productId: item.productId,
                  image: item.mediaItem.src,
                  title: item.name,
                  options: item.options,
                };
              });
            }
            return [];
          })
          .catch((err) => {
            console.log('Problem getting the cart:', err);
            return [];
          });
      }

      function setRepeaterData(data) {
        $w('#repeater1').data = data;
        updateRepeaterView();
        // showRepeater();
      }

      function updateRepeaterView() {
        $w('#repeater1').onItemReady(function ($item, itemData) {
          console.log({ itemData });
          try {
            $item('#imageX1').fitMode = 'fixedWidth';
            $item('#imageX1').src = itemData.image;
            $item('#text2').text = itemData.title;
            $item('#text3').text = itemData.price;
            $item('#button1').onClick(function () {
              addToCart(itemData.productId, itemData.options);

              console.log('button clicked');
            });
          } catch (e) {
            console.log('error', e);
          }
        });
      }

      function addToCart(productId, options = { choices: undefined }) {
        const opts = options?.choices
          ? {
              options: {
                choices: options.choices,
              },
            }
          : {};

        stores.cart.addProducts([
          {
            productId,
            quantity: 1,
            ...opts,
          },
        ]);
      }

      // function hideRepeater() {
      //   $w('#box2').collapse();
      //   $w('#text1').collapse();
      // }

      // function showRepeater() {
      //   $w('#box2').expand();
      //   $w('#text1').expand();
      // }
    },
    exports: {},
  };
});
